@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Courgette&display=swap');

.home{
  background: linear-gradient(to right,#ccfed8,#a3cbf6 );
}
body {
  font-family: 'Poppins', sans-serif;
}
.scrolling-container {
  max-height: 100%;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

.scrolling-container::-webkit-scrollbar {
  width: 1px;
}

.scrolling-container::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.scrolling-container::-webkit-scrollbar-track {
  background-color: transparent;
}
@media screen and (min-width : 500px)
{
  .home > .home_text{
    font-size: 20rem;
    font-weight: bold;
    background-color: antiquewhite;
  }
  .tabs{
    height: fit-content;
  }
  
  .demo{
    display: grid;
    grid-gap: 6rem;
  }
}

@media screen and (max-width:768px ) {
  .profile{
    height: 15em;
  }
  .info{
    text-align: center;
  }
  .home > p{
    font-size: 1rem;
    font-weight: bold;
  }
  .links{
    padding-bottom: 8px;
  }
  .about_me {
    display: flex;
    flex-direction: column-reverse;
  }
  .tabs{
    font-size: medium;
    height: max-content;
  }
  .tabs_text{
    font-size: medium;
  }
  .demo{
    display: grid;
    grid-gap: 6rem;
    text-align: justify;
  }
  .time_period{
    display: flex;
    justify-content: center;
  }
}